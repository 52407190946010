import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormatExtensionVideoEnum } from '../../../../../enum/formatExtensionVideo.enum';
import {
	TicketModel,
	AssetTypeFormFieldList,
	TicketBaseModel,
	AssetTypeFormFieldItem
} from '../../../../../model/asset-request/ticket.model';
import { Observable, merge, combineLatest } from 'rxjs';
import { UtilService } from '../../../../../service/util/util.service';
import { AssetRequestUtilService } from '../../../../../service/util/asset-request-util.service';
import { skipWhile, map, startWith } from 'rxjs/operators';
import { RestAttachmentListManagerService, SubscribeManagerService } from '@saep-ict/angular-core';
import { MediaReplayService } from '@saep-ict/angular-core';
import { AssetTypeVideoDiffusionTypeEnum } from '../../../../../enum/AssetTypeVideoDiffusionType.enum';
import * as moment from 'moment';
import { AssetVersionEnum } from '../../../../../enum/AssetVersion.enum';
import { RequestCenterEnum } from '../../../../../enum/request-center/request-center.enum';
import { RequestCenterDialogBaseDirective } from '../../../misc/request-center-dialog-base.directive';
import { EnumIteratorModel } from '../../../../../shared/pipes/enum-iterator';
import { StoreUtilService } from '../../../../../service/util/store-util.service';
import { Store } from '@ngrx/store';
import _ from 'lodash';

@Component({
	selector: 'app-dialog-request-center-advertising-material',
	templateUrl: './dialog-request-center-advertising-material.component.html',
	styleUrls: ['./dialog-request-center-advertising-material.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogRequestCenterAdvertisingMaterial
	extends RequestCenterDialogBaseDirective<
		DialogRequestCenterAdvertisingMaterial,
		TicketModel.AssetItem.AdvertisingMaterial
	>
	implements OnInit, OnDestroy {
	formatExtensionVideoEnum = FormatExtensionVideoEnum;
	assetTypeVideoDiffusionTypeEnum = AssetTypeVideoDiffusionTypeEnum;
	assetVersionEnum = AssetVersionEnum;

	durationList: string[];

	dateDueMin = Date.now();

	fieldList: AssetTypeFormFieldItem[];

	constructor(
		/**
		 * @param data contain the following properties
		 * - `asset: AssetState`
		 * - `ticket: TicketStateModel<ASSET>`
		 * - `userType: string` the value must be on the following: 'company', 'backoffice', 'agency'
		 * - `ticketStatus: RequestCenterEnum.Status.base`
		 */
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<DialogRequestCenterAdvertisingMaterial>,
		public utilService: UtilService,
		public assetRequestUtilService: AssetRequestUtilService,
		private subscribeManagerService: SubscribeManagerService,
		public mediaReplayService: MediaReplayService,
		public store: Store,
		public utilStoreService: StoreUtilService,
		public restAttachmentListManagerService: RestAttachmentListManagerService
	) {
		super(dialogRef, utilService, store, utilStoreService, restAttachmentListManagerService);
		this.assignDataToDialog(this.data);
		this.requestType = RequestCenterEnum.type.IMAGE_VIDEO;
		this.setAttachmentListManagerConfiguration();
		this.createForm();

		this.subscribeManagerService.populate(this.subscribeTypeFormChange(), 'type-form-change');
		this.subscribeManagerService.populate(this.subscribeBrandAndLineFormChange().subscribe(), 'brand-form-change');
		this.subscribeManagerService.populate(
			this.subscribeDimensionVisibleAndTotalFormChange().subscribe(),
			'dimension-visible-total-form-change'
		);

		this.setForm();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	subscribeTypeFormChange() {
		return this.form
			.get('type')
			.valueChanges.subscribe((val: TicketBaseModel<RequestCenterEnum.Asset.Type.AdvertisingMaterial>) => {
				if (val) {
					const brandCode = this.form.get('brand').value;
					const lineCode = this.form.get('line').value;
					const versionCode = this.form.get('version').value;
					this.updateLineAndDurationList(brandCode ? brandCode.code : null, lineCode ? lineCode.code : null, versionCode? versionCode.id : null);
					this.updateDynamicFieldList(val.id);
				}
			});
	}

	subscribeBrandAndLineFormChange() {
		const brand$ = this.form
			.get('brand')
			.valueChanges.pipe(startWith(this.asset.brand && this.asset.brand ? this.asset.brand : null));
		const line$ = this.form
			.get('line')
			.valueChanges.pipe(startWith(this.asset.line && this.asset.line ? this.asset.line : null));
		const version$ = this.form
		.get('version')
		.valueChanges.pipe(startWith(this.asset.version && this.asset.version ? this.asset.version : null));
		return combineLatest(brand$, line$, version$).pipe(
			skipWhile(([brand, line, version]) => !(brand || line || version)),
			map(([brand, line, version]) => {
				this.updateLineAndDurationList(brand ? brand.code : null, line ? line.code : null, version ? version.id : null);
			})
		);
	}

	subscribeDimensionVisibleAndTotalFormChange() {
		const subscribeKey: string[] = [
			'dimension_visible_width',
			'dimension_visible_height',
			'dimension_total_width',
			'dimension_total_height'
		];
		const subscribe: Observable<number>[] = [];
		subscribeKey.forEach(i => {
			subscribe.push(this.form.get(i).valueChanges.pipe());
		});
		return merge(...subscribe).pipe(
			skipWhile(data => !data),
			map(data => {
				subscribeKey.forEach(i => {
					this.form.get(i).updateValueAndValidity({ emitEvent: false });
				});
			})
		);
	}

	showAssetVersion(item: EnumIteratorModel): boolean {
		if (
			(item.id === AssetVersionEnum.PACKSHOT &&
				this.fieldTypeValue === RequestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO && this.fieldBrand?.code !== RequestCenterEnum.Asset.BrandCode.MICHAEL_KORS && this.fieldLine?.description !== RequestCenterEnum.Asset.LineCodeEnum.NAJIM ) ||
			(item.id === AssetVersionEnum.DIGITAL_ASSETS &&
				this.fieldTypeValue !== RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER) || 
			(this.fieldLine?.description === RequestCenterEnum.Asset.LineCodeEnum.HOLIDAY24 && item.id !== AssetVersionEnum.PACKSHOT) || 
			(this.fieldLine?.description === RequestCenterEnum.Asset.LineCodeEnum.NAJIM 
				&& item.id !== AssetVersionEnum.PACKSHOT_WITH_INGREDIENTS && item.id !== AssetVersionEnum.PACKSHOT) ||
			(this.fieldLine?.description !== RequestCenterEnum.Asset.LineCodeEnum.NAJIM && item.id === AssetVersionEnum.PACKSHOT_WITH_INGREDIENTS)	
			)
		{
			return false;
		}
		return true;
	}

	// widget & utility
	// form
	createForm() {
		const isDisabled =
			this.ticketStatus === RequestCenterEnum.Status.base.DRAFT ||
			(this.ticketStatus === RequestCenterEnum.Status.base.MORE_INFO_NEEDED &&
				this.userType === RequestCenterEnum.User.type.COMPANY)
				? false
				: true;

		this.form = this.fb.group({
			type: [
				{ value: RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER, disabled: isDisabled },
				Validators.required
			],
			brand: [{ value: null, disabled: isDisabled }, Validators.required],
			line: [{ value: null, disabled: isDisabled }, Validators.required],
			gwp_code: [{ value: null, disabled: isDisabled }],

			cta: [{ value: null, disabled: isDisabled }],
			// resolution: [{ value: null, disabled: isDisabled }],
			width: [{ value: null, disabled: isDisabled }],
			height: [{ value: null, disabled: isDisabled }],
			file_extension: [{ value: null, disabled: isDisabled }],
			duration: [{ value: null, disabled: isDisabled }],
			digital_width: [{ value: null, disabled: isDisabled }],
			digital_height: [{ value: null, disabled: isDisabled }],
			version: [{ value: null, disabled: isDisabled }],

			date_release: [{ value: null, disabled: isDisabled }],
			video_diffusion_type: [{ value: null, disabled: isDisabled }],
			country: [{ value: null, disabled: isDisabled }],
			city: [{ value: null, disabled: isDisabled }],
			client: [{ value: null, disabled: isDisabled }],

			measure: [{ value: null, disabled: isDisabled }],

			dimension_visible_width: [{ value: null, disabled: isDisabled }],
			dimension_visible_height: [{ value: null, disabled: isDisabled }],

			dimension_total_width: [{ value: null, disabled: isDisabled }],
			dimension_total_height: [{ value: null, disabled: isDisabled }],

			note: [{ value: null, disabled: isDisabled }]
		});
	}

	get fieldTypeValue() {
		return this.form.get('type').value['id'];
	}
	get fieldBrand() {
		return this.form.get('brand')?.value;
	}
	get fieldLine() {
		return this.form.get('line')?.value;
	}

	/**
	 * Aggiorna le option selezionabili di line e duration, invalidandone eventualmente i valori dei campi
	 *
	 * @param {string} [brandFieldValue]
	 * @param {string} [lineFieldValue]
	 * @memberof DialogRequestCenterAdvertisingMaterial
	 */
	updateLineAndDurationList(brandFieldValue?: string, lineFieldValue?: string, versionFieldValue?: AssetVersionEnum) {
		this.lineList = [];
		this.durationList = [];
		if (brandFieldValue) {
			const brandDetail = this.assetRequestUtilService.categoryList.category_list.find(
				item => item.code === brandFieldValue
			);
			if (brandDetail) {
				this.lineList = brandDetail.category_list;
				// GWP code
				if (
					this.fieldTypeValue !== RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER &&
					this.fieldTypeValue !== RequestCenterEnum.Asset.Type.AdvertisingMaterial.INSTORE_VISUAL_FOR_PRINT
				) {
					this.lineList = this.lineList.filter(
						item => item.description !== RequestCenterEnum.Asset.LineCodeEnum.GWP
					);
				}
				// Linea Holiday23 eliminata
				// if (this.fieldTypeValue === RequestCenterEnum.Asset.Type.AdvertisingMaterial.MAGAZINE) {
				// 	this.lineList = this.lineList.filter(
				// 		item => item.description !== RequestCenterEnum.Asset.LineCodeEnum.HOLIDAY23
				// 	);
				// }
				// Nuova Linea MK Holiday24 ha come possibili opzioni  Web Banner, Instore Visual for Print/ for Monitor
				if (
					this.fieldTypeValue === RequestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO ||
					this.fieldTypeValue === RequestCenterEnum.Asset.Type.AdvertisingMaterial.MAGAZINE
				) {
					this.lineList = this.lineList.filter(
						item => item.description !== RequestCenterEnum.Asset.LineCodeEnum.HOLIDAY24
					);
				}
				if (this.fieldTypeValue !== RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER) {
					this.lineList = this.lineList.filter(
						item =>
							item.description !== RequestCenterEnum.Asset.LineCodeEnum.ATKINSON_COLLECTION_MISTO &&
							item.description !== RequestCenterEnum.Asset.LineCodeEnum.NAJ_CREAMY_DELIGHT_LIPSTICK
					);
				}
				if (this.fieldTypeValue === RequestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO) {
					this.lineList = this.lineList.filter(
						item => item.description !== RequestCenterEnum.Asset.LineCodeEnum.WONDERLUST //WUNDERLUST
					);
				}

				// Video duration
				this.lineList = this.lineList.filter(item =>
					this.fieldTypeValue === RequestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO
						? item.duration && item.duration.length > 0
						: item
				);
				if (this.lineList && this.lineList.length > 0) {
					const lineDetail = this.lineList.find(i => i.code === lineFieldValue);
					if (lineDetail) {
						if (lineDetail.code !== RequestCenterEnum.Asset.LineCodeEnum.GWP) {
							this.form.get('gwp_code').setValue(null, { emitEvent: false });
						}
						if (lineDetail.duration) {
							this.durationList = lineDetail.duration;
							if((versionFieldValue === AssetVersionEnum.MIDDLE_EAST  || versionFieldValue === AssetVersionEnum.PACKSHOT ) 
								&& lineDetail.code ===  RequestCenterEnum.Asset.LineCodeEnum.MK_POUR_HOMME_FEMME ) {
								this.durationList = lineDetail.duration.filter(item => item === RequestCenterEnum.Asset.Duration.DURATION_15)
							} 
							if (!this.durationList.find(i => i === this.form.get('duration').value)) {
								this.form.get('duration').setValue(null, { emitEvent: false });
							}
						} else {
							this.form.get('duration').setValue(null, { emitEvent: false });
						}
					} else {
						this.form.get('line').setValue(null, { emitEvent: false });
						this.form.get('gwp_code').setValue(null, { emitEvent: false });
						this.form.get('duration').setValue(null, { emitEvent: false });
					}
				} else {
					this.form.get('line').setValue(null, { emitEvent: false });
					this.form.get('gwp_code').setValue(null, { emitEvent: false });
					this.form.get('duration').setValue(null, { emitEvent: false });
				}
			}
		} else {
			this.form.get('line').setValue(null, { emitEvent: false });
			this.form.get('gwp_code').setValue(null, { emitEvent: false });
			this.form.get('duration').setValue(null, { emitEvent: false });
		}
	}

	/**
	 * Aggiorna l'oggetto form modificando il set di campi dinamici ed i loro validatori mappati in
	 * assetRequestUtilService.assetTypeFormFieldMap
	 *
	 * @param {number} typeValue
	 * @memberof DialogRequestCenterAdvertisingMaterial
	 */
	updateDynamicFieldList(typeFieldValue: number | RequestCenterEnum.Asset.Type.AdvertisingMaterial) {
		// se cambio il field asset type persisto il valore di asset version
		const versionValue: TicketBaseModel<AssetVersionEnum> = this.form.get('version').value;
		if (
			this.fieldTypeValue === RequestCenterEnum.Asset.Type.AdvertisingMaterial.VIDEO &&
			versionValue &&
			versionValue.id === AssetVersionEnum.PACKSHOT && this.fieldBrand.code !== RequestCenterEnum.Asset.BrandCode.MICHAEL_KORS
		) {
			this.form.get('version').setValue(null, { emitEvent: false });
			this.form.get('version').clearValidators();
		}

		// In base al field asset type recupero da assetTypeFormFieldMap la mappa dei field
		const typeFieldListSelected: AssetTypeFormFieldList = this.assetRequestUtilService.assetTypeFormFieldMap[
			this.utilService.getElementIndex(this.assetRequestUtilService.assetTypeFormFieldMap, 'type', typeFieldValue)
		];

		// Ciclo tutti gli elementi di assetTypeFormFieldMap
		// per pulire ogni campo possibile da eventuali validator e valori inseriti
		this.assetRequestUtilService.assetTypeFormFieldMap.forEach(typeList => {
			typeList.fieldList.forEach(field => {
				if (!typeFieldListSelected.fieldList.find(f => f.key === field.key)) {
					this.form.get(field.key).clearValidators();
					this.form.get(field.key).setValue(null, { emitEvent: false });
				}
			});
		});

		// Setto i validators necessari
		typeFieldListSelected.fieldList.forEach(i => {
			// Nascondo gli avvisi di errore
			this.form.get(i.key).markAsUntouched();
			// setto i validators
			this.form.get(i.key).setValidators(i.validator ? i.validator : null);
		});

		// Field list for selected asset type
		this.fieldList = typeFieldListSelected.fieldList;
	}

	/**
	 * Aggiorna il setup generale della form. Il metodo scatta sia in modifica che in creazione dell'asset
	 *
	 * @memberof DialogRequestCenterAdvertisingMaterial
	 */
	setForm() {
		const asset: TicketModel.AssetItem.AdvertisingMaterial = this.asset;
		this.form.patchValue(
			{
				brand: asset.brand ? asset.brand : null,
				line: asset.line ? asset.line : null,
				type: asset.type
					? asset.type
					: {
							id: RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER,
							description:
								RequestCenterEnum.Asset.Type.AdvertisingMaterial[
									RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER
								]
					  },
				gwp_code: asset.gwp_code ? asset.gwp_code : null,
				cta: asset.cta ? asset.cta : null,
				// resolution: asset.resolution ? asset.resolution : null,
				file_extension: asset.file_extension ? asset.file_extension : null,
				duration: asset.duration ? asset.duration : null,
				version: asset.version ? asset.version : null,
				note: asset.note ? asset.note : null,
				measure: asset.measure ? asset.measure : null
			},
			{ emitEvent: false }
		);

		if (asset.digital_dimension) {
			this.form.patchValue(
				{
					digital_width: asset.digital_dimension.width ? asset.digital_dimension.width : null,
					digital_height: asset.digital_dimension.height ? asset.digital_dimension.height : null
				},
				{ emitEvent: false }
			);
		}

		if (asset.diffusion) {
			this.form.patchValue(
				{
					date_release: asset.diffusion.date_release ? asset.diffusion.date_release : null,
					video_diffusion_type: asset.diffusion.video_diffusion_type
						? asset.diffusion.video_diffusion_type
						: null,
					country: asset.diffusion.country ? asset.diffusion.country : null,
					city: asset.diffusion.city ? asset.diffusion.city : null,
					client: asset.diffusion.client ? asset.diffusion.client : null
				},
				{ emitEvent: false }
			);
		}

		if (asset.dimension_visible) {
			this.form.patchValue(
				{
					dimension_visible_width: asset.dimension_visible.width ? asset.dimension_visible.width : null,
					dimension_visible_height: asset.dimension_visible.height ? asset.dimension_visible.height : null
				},
				{ emitEvent: false }
			);
		}

		if (asset.dimension_total) {
			this.form.patchValue(
				{
					dimension_total_width: asset.dimension_total.width ? asset.dimension_total.width : null,
					dimension_total_height: asset.dimension_total.height ? asset.dimension_total.height : null
				},
				{ emitEvent: false }
			);
		}

		this.updateLineAndDurationList(asset.brand ? asset.brand.code : null, asset.line ? asset.line.code : null);
		this.updateDynamicFieldList(
			asset.type ? asset.type.id : RequestCenterEnum.Asset.Type.AdvertisingMaterial.WEB_BANNER
		);
	}

	prepareSaveForm(): TicketModel.AssetItem.AdvertisingMaterial {
		const formModel = this.form.getRawValue();
		const newAsset: TicketModel.AssetItem.AdvertisingMaterial = {
			code_item: this.asset.code_item,
			brand: formModel.brand ? formModel.brand : null,
			line: formModel.line ? formModel.line : null,
			type: formModel.type ? formModel.type : null,
			note: formModel.note ? formModel.note : null,
			gwp_code: formModel.gwp_code ? formModel.gwp_code : null,
			cta: formModel.cta ? formModel.cta : null,
			// resolution: formModel.resolution ? formModel.resolution : null,
			file_extension: formModel.file_extension ? formModel.file_extension : null,
			duration: formModel.duration ? formModel.duration : null,
			version: formModel.version ? formModel.version : null,
			measure: formModel.measure ? formModel.measure : null,
			diffusion: this.utilService.deleteEmptyProperties({
				// TODO - check milliseconds
				date_release: formModel.date_release
					? parseInt(moment(formModel.date_release).format('x'), null)
					: null,
				video_diffusion_type: formModel.video_diffusion_type ? formModel.video_diffusion_type : null,
				country: formModel.country ? formModel.country : null,
				city: formModel.city ? formModel.city : null,
				client: formModel.client ? formModel.client : null
			}),
			digital_dimension: this.utilService.deleteEmptyProperties({
				width: formModel.digital_width ? formModel.digital_width : null,
				height: formModel.digital_height ? formModel.digital_height : null
			}),
			dimension_visible: this.utilService.deleteEmptyProperties({
				width: formModel.dimension_visible_width ? formModel.dimension_visible_width : null,
				height: formModel.dimension_visible_height ? formModel.dimension_visible_height : null
			}),
			dimension_total: this.utilService.deleteEmptyProperties({
				width: formModel.dimension_total_width ? formModel.dimension_total_width : null,
				height: formModel.dimension_total_height ? formModel.dimension_total_height : null
			})
		};
		this.setFormAttachmentTypeList(newAsset);
		return newAsset;
	}

	setAttachmentListManagerConfiguration() {
		this.attachmentTypeList =
			[RequestCenterEnum.Asset.AttachmentType.AGENCY, RequestCenterEnum.Asset.AttachmentType.FRONTOFFICE];
		this.setAttachmentListManagerConfigurationBase();
		// upload_frontoffice
		this.attachmentListManagerConfiguration[RequestCenterEnum.Asset.AttachmentType.FRONTOFFICE].privilege = {
			add:
				(
					this.ticketStatus === RequestCenterEnum.Status.base.DRAFT ||
					this.ticketStatus === RequestCenterEnum.Status.base.MORE_INFO_NEEDED
				) &&
				this.userType === RequestCenterEnum.User.type.COMPANY,
			delete:
				(
					this.ticketStatus === RequestCenterEnum.Status.base.DRAFT ||
					this.ticketStatus === RequestCenterEnum.Status.base.MORE_INFO_NEEDED
				) &&
				this.userType == RequestCenterEnum.User.type.COMPANY,
			download: true
		};
		// upload_agency
		this.attachmentListManagerConfiguration[RequestCenterEnum.Asset.AttachmentType.AGENCY].privilege = {
			add:
				(
					this.ticketStatus === RequestCenterEnum.Status.base.PENDING_EUROITALIA &&
					this.userType === RequestCenterEnum.User.type.EUROITALIA
				) ||
				(
					this.ticketStatus === RequestCenterEnum.Status.base.PENDING_AGENCY &&
					this.userType === RequestCenterEnum.User.type.AGENCY
				),
			delete:
				(
					this.ticketStatus == RequestCenterEnum.Status.base.PENDING_EUROITALIA &&
					this.userType === RequestCenterEnum.User.type.EUROITALIA
				) ||
				(
					this.ticketStatus === RequestCenterEnum.Status.base.PENDING_AGENCY &&
					this.userType === RequestCenterEnum.User.type.AGENCY
				),
			download: true
		};
	}

}