export namespace RequestCenterEnum {
	export enum type {
		IMAGE_VIDEO = 'image-video',
		PRINT_PRODUCTION_FLOW = 'print-production-flow'
	}
	export enum storeActionKey {
		IMAGE_VIDEO = 'asset_request_center_ticket',
		PRINT_PRODUCTION_FLOW = 'asset_request_center_print_production_flow'
	}
	export namespace User {
		export enum type {
			COMPANY = 'company',
			EUROITALIA = 'euroitalia',
			AGENCY = 'agency'
		}
	}
	export namespace Status {
		export enum base {
			DRAFT = 1,
			PENDING_EUROITALIA = 2,
			PENDING_AGENCY = 3,
			MORE_INFO_NEEDED = 4,
			COMPLETED_AGENCY = 5,
			SENT_TO_CLIENT = 6,
			COMPLETED_EUROITALIA = 7,
			DELETED = 999999999
		}
	}
	export namespace Asset {
		export namespace Type {
			export enum AdvertisingMaterial {
				WEB_BANNER = 1,
				VIDEO = 2,
				INSTORE_VISUAL_FOR_PRINT = 3,
				MAGAZINE = 4,
				INSTORE_VISUAL_FOR_MONITOR = 5
			}
			export enum AdvertisingMaterialDescription {
				WEB_BANNER = 'Web banner',
				VIDEO = 'Video',
				INSTORE_VISUAL_FOR_PRINT = 'Instore visual for print',
				MAGAZINE = 'Magazine',
				INSTORE_VISUAL_FOR_MONITOR = 'Instore visual for monitor'
			}
			export namespace PrintProductionFlow {
				export enum Code {
					ESECUTIVO_DECORI_PRIMARIO = 11,
					ESECUTIVO_ETICHETTE = 12,
					ESECUTIVO_ETICHETTE_LIBRO_TWIN_LABEL = 13,
					ESECUTIVO_ETICHETTE_TIPO_TESTER = 14,
					ESECUTIVO_TUBI = 15,
					ESECUTIVO_PACK_SECONDARIO = 16,
					ESECUTIVO_COFANETTI_SPRING_NATALE = 17,
					ESECUTIVO_GUAINA_COFANETTI_SPRING_NATALE = 18,
					ESECUTIVO_MASCHERA_COFANETTI_SPRING_NATALE = 19,
					ESECUTIVO_SCATOLA_COUVETTE_PRESENTAZIONE_LINEA = 20,
					MARKETING_BOOK_PRESENTAZIONE_LINEA = 21,
					RENDERING_CON_DIDA = 22,
					RENDERING_SENZA_DIDA = 23,
					RENDERING_1500X1500 = 24,
					PROVE_COLORE_CROMALINE_GMG_CERT_FOGRA_A4_FINO_25X35 = 25,
					PROVE_COLORE_CROMALINE_GMG_CERT_FOGRA_A3_FINO_35X45 = 26,
					PROVE_COLORE_CROMALINE_GMG_CERT_FOGRA_50X70 = 27
				}
				export enum ShortDescription {
					ESECUTIVO_DECORI_PRIMARIO = 'decori primario',
					ESECUTIVO_ETICHETTE = 'etichette',
					ESECUTIVO_ETICHETTE_LIBRO_TWIN_LABEL = 'libro twin label',
					ESECUTIVO_ETICHETTE_TIPO_TESTER = 'tester',
					ESECUTIVO_TUBI = 'tubi',
					ESECUTIVO_PACK_SECONDARIO = 'es pack secondario',
					ESECUTIVO_COFANETTI_SPRING_NATALE = 'cofanetti spring / natale',
					ESECUTIVO_GUAINA_COFANETTI_SPRING_NATALE = 'guaina spring / natale',
					ESECUTIVO_MASCHERA_COFANETTI_SPRING_NATALE = 'maschera spring / natale',
					ESECUTIVO_SCATOLA_COUVETTE_PRESENTAZIONE_LINEA = 'scatola couvette',
					MARKETING_BOOK_PRESENTAZIONE_LINEA = 'marketing book',
					RENDERING_CON_DIDA = 'rendering con dida',
					RENDERING_SENZA_DIDA = 'rendering senza dida',
					RENDERING_1500X1500 = 'rendering 1500x1500',
					PROVE_COLORE_CROMALINE_GMG_CERT_FOGRA_A4_FINO_25X35 = 'GMG cert FOGRA A4',
					PROVE_COLORE_CROMALINE_GMG_CERT_FOGRA_A3_FINO_35X45 = 'GMG cert FOGRA A3',
					PROVE_COLORE_CROMALINE_GMG_CERT_FOGRA_50X70 = 'GMG cert FOGRA 50x70'
				}
			}
		}
		export enum AttachmentType {
			EUROITALIA = 'upload_euroitalia',
			AGENCY = 'upload_agency',
			FRONTOFFICE = 'upload_frontoffice'
		}
		export enum AttachmentNameOnBucketType {
			upload_euroitalia = 'euroitalia',
			upload_agency = 'agency',
			upload_frontoffice = 'client'
		}
		export enum icon {
			WEB_BANNER = 'video_library',
			VIDEO = 'movie',
			INSTORE_VISUAL_FOR_PRINT = 'image',
			INSTORE_VISUAL_FOR_MONITOR = 'image',
			MAGAZINE = 'account_balance',

			ESECUTIVO_DECORI_PRIMARIO = 'extension',
			ESECUTIVO_ETICHETTE_TESTER = 'extension',
			ESECUTIVO_MASCHERA_SPRING_XMAS = 'extension',
			ESECUTIVO_PACK_SECONDARIO = 'extension',
			ESECUTIVO_SCATOLA_COUVETTE_PRESENTAZIONE = 'extension',
			ESECUTIVO_SCATOLA_SPRING_XMAS = 'extension',
			ESECUTIVO_TUBI = 'extension',
			MARKETING_BOOK = 'extension',
			PROVE_COLORE_CROMALINE_GMC_CERT_FOGRA_50X70 = 'extension',
			PROVE_COLORE_CROMALINE_GMC_CERT_FOGRA_A3_35X45 = 'extension',
			PROVE_COLORE_CROMALINE_GMC_CERT_FOGRA_A4_25X35 = 'extension',
			RENDERING_1500X1500 = 'extension',
			RENDERING_CON_DIDA = 'extension',
			RENDERING_SENZA_DIDA = 'extension'
		}
		export enum ImagePreviewExtension {
			JPG = 'jpg',
			JPEG = 'jpeg',
			PNG = 'png'
		}
		export enum LineCodeEnum {
			GWP = 'GWP',
			HOLIDAY24 = 'HOLIDAY 2024',
			WONDERLUST = 'WONDERLUST', // 90
			ATKINSON_COLLECTION_MISTO = 'ATKINSONS COLLECTION - MISTO', // 92
			NAJ_CREAMY_DELIGHT_LIPSTICK = 'CREAMY DELIGHT LIPSTICK', // 93,
			MK_POUR_HOMME_FEMME = '23',
			NAJIM = 'NAJIM'
		}
		export enum BrandCode {
			MICHAEL_KORS = 'MK',
			ATKINSONS = 'AK',
			MOSCHINO = '06',
			MISSONI = '80',
			ATELIER_VERSACE = 'VX',
			DSQUARED2 = '50',
			VERSACE = 'V0',
			NAJ_OLEARI_COSMETICS = '04'
		}
		export enum BrandDescription {
			MICHAEL_KORS = 'MICHAEL KORS',
			ATKINSONS = 'ATKINSONS',
			MOSCHINO = 'MOSCHINO',
			MISSONI = 'MISSONI',
			ATELIER_VERSACE = 'ATELIER VERSACE',
			DSQUARED2 = 'DSQUARED2',
			VERSACE = 'VERSACE',
			NAJ_OLEARI_COSMETICS = 'NAJ-OLEARI COSMETICS'
		}
		export enum Duration {
			DURATION_15 = '15'
		}
	}
}
